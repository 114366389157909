import routes from "../routes";

// Utility to convert route patterns to regex
const routeToRegex = (route) => {
  const pattern = route
    .replace(/\[.*?]/g, "[^/]+") // Replace [param] with any characters except '/'
    .replace(/\[\[.*?]]/g, "([^/]+)?"); // Handle optional [[param]]
  return new RegExp(`^${pattern}$`);
};

function normalizeUrlPattern(urlPattern: string): string {
  return urlPattern.replace(/\[.*?]/g, ":dynamicParam").replace(/\/$/, ""); // Remove trailing slash
}

function findNextFileRoute(pathname: string) {
  for (const route of routes) {
    const regex = routeToRegex(route);
    if (regex.test(pathname)) {
      return route;
    }
  }
  return null;
}

// exact match pattern with pattern
// ex. /[medspaId]/clients/[slug] will match /[medspaId]/clients/[slug] but will not match /[medspaId]/clients/charts
function hasPermissionToNextRoute(
  route: string,
  urlPatternsPermissions: string[]
) {
  const transformedRoute = normalizeUrlPattern(route);
  return urlPatternsPermissions.some((pattern) => {
    return transformedRoute === normalizeUrlPattern(pattern);
  });
}

export function hasPermissionsToAccessUrl(
  nextUrl: string,
  urlPatternsPermissions: string[]
): boolean {
  if (!nextUrl) return false;
  const strippedUrl = nextUrl.split("?")[0];
  const nextRoutePattern = findNextFileRoute(strippedUrl);
  if (nextRoutePattern === null) {
    return false;
  }
  return hasPermissionToNextRoute(nextRoutePattern, urlPatternsPermissions);
}

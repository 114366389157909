// BE: GfeTreatmentIndicationTypeChoices
export enum IndicationType {
  REVIEW_REQUESTED = "review_requested",
  INDICATED = "indicated",
  CONTRAINDICATED = "contraindicated",
  NOT_INDICATED = "not_indicated",
}

// BE: FormSubmissionStatus -> GFEStatus
export enum FormStatus {
  INCOMPLETE = "incomplete",
  COMPLETED = "client_completed",
  EXPIRED = "expired",
}

export enum FormStatusExtra {
  UNSENT = "unsent", // services offered by medspa, but forms weren't sent yet
  NOT_NEEDED = "", // the form's gfe status wasn't set at all, which means no GFE is needed
}

// BE: VisitGFEStatus
export enum DeprecatedFinalGfeVisitStatus {
  FORMS_INCOMPLETE = "forms_incomplete",
  FORMS_COMPLETE = "forms_complete",
  FORMS_EXPIRED = "forms_expired",
  PENDING_REVIEWER = "pending_reviewer",
  APPROVED = "approved",
  DECLINED = "declined",
  NOT_NEEDED = "not_needed",
}

// BE: ServiceMenuItemGFEStatus
export enum ServiceMenuItemGfeStatus {
  FORMS_INCOMPLETE = "forms_incomplete",
  PENDING_REVIEWER = "pending_reviewer",
  NOT_INDICATED = "not_indicated",
  CONTRAINDICATED = "contraindicated",
  INDICATED_WITH_EXCEPTIONS = "indicated_with_exceptions",
  INDICATED = "indicated",
  NOT_NEEDED = "not_needed",
}

export enum ReviewCardGfeStatus {
  PENDING_REVIEWER = "pending_reviewer",
  COMPLETED = "completed",
}

// BE: GfeInputIndicationTypeEnum
export enum SyncGfeIndicationType {
  INDICATED = "indicated",
  CONTRAINDICATED = "contraindicated",
  NOT_INDICATED = "not_indicated",
}

// BE: GfeSynchronousReviewDocument.DeviceChoices
export enum DeviceType {
  PROVIDER = "provider",
  OTHER = "other",
}

import { addHours, isAfter, isBefore } from "date-fns";
import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";
import { useConfirm } from "@/components/providers/confirmProvider";
import { APP_URL_BASE } from "@/config";
import { useCreateShortUrlMutation } from "@/graphql/mutations/createShortUrl.graphql.types";
import { useCategoriesWithClientIndicationsQuery } from "@/graphql/queries/categories.graphql.types";
import { useClientsCardsOnFileLazyQuery } from "@/graphql/queries/clientsCardsOnFile.graphql.types";
import { useFrontDeskBookingInfoQuery } from "@/graphql/queries/frontDeskBookingInfo.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import { ClientForVisitCreation } from "@/hooks/visits/useCreateVisit";
import { Roles } from "@/types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

export default function useFrontDeskBooking(
  start: Date,
  end: Date,
  client: ClientForVisitCreation,
  providerId: string,
  services: string[],
  handleCreateVisit: () => Promise<void>
) {
  const { push } = useRouter();
  const { medspa, newPermissionsEnabledForUser, hasFeaturePermission } =
    useUser();
  const isFrontDesk = useUserHasRoles([Roles.FRONT_DESK]);
  const logError = useErrorLogger();
  const [getClientsCardOnFile] = useClientsCardsOnFileLazyQuery();
  const { getConfirm } = useConfirm();
  const { utcToMedspaZonedTime, zonedTimeToUtcIso } = useMedspaTimezone();

  const [createShortUrl] = useCreateShortUrlMutation();

  const skipFrontDeskBookingData = newPermissionsEnabledForUser
    ? !hasFeaturePermission(FeaturePermission.VIEW_FRONT_DESK_BOOKING_INFO)
    : !isFrontDesk;
  const { data: frontDeskBookingData } = useFrontDeskBookingInfoQuery({
    variables: {
      medspaId: medspa,
    },
    skip: !medspa || skipFrontDeskBookingData,
  });

  const skipCategoriesWithClientIndications = newPermissionsEnabledForUser
    ? !hasFeaturePermission(
        FeaturePermission.VIEW_CATEGORIES_WITH_CLIENT_INDICATIONS
      )
    : !isFrontDesk;
  const { data: categoriesData } = useCategoriesWithClientIndicationsQuery({
    variables: {
      medspaId: medspa,
      clientId: client?.id,
    },
    skip: !medspa || skipCategoriesWithClientIndications || !client,
    fetchPolicy: "cache-and-network",
  });

  const getClientsCreditCard = async (clientId: string) => {
    try {
      const { data } = await getClientsCardOnFile({
        variables: {
          clientId,
        },
      });

      return data.clientByPk.stripeData?.stripeCardsList?.[0];
    } catch (e) {
      logError("Unexpected error while getting client's card on file", e);
      return null;
    }
  };

  const generateShortLink = async (fullUrl: string) => {
    try {
      const { data } = await createShortUrl({ variables: { fullUrl } });
      const uuid = data?.createShortMoxieUrl.uuid;
      const shortUrl = `${APP_URL_BASE}/url/${uuid}`;
      return shortUrl;
    } catch (e) {
      logError(e.message);
      toast.error(
        hasDjangoMutationError(e)
          ? e.message
          : `There was a problem with creating the URL`
      );
    }
  };

  const checkIfAllServicesAreBookableOnline = () => {
    const onlineBookableServices = categoriesData?.serviceCategory
      .flatMap((sc) => sc.medspaServiceMenuItems)
      .filter((s) => s.isOnlineBookingEnabled)
      .map((s) => s.id);

    const hasInvalidServices = services.some(
      (s) => !onlineBookableServices.includes(s)
    );

    if (!hasInvalidServices) return true;

    toast.error(
      "This service cannot be booked online. Providers can update this service in their Moxie Suite settings."
    );
    return false;
  };

  const bookAsFrontDesk = async () => {
    // shouldn't happen as we preload that
    if (!frontDeskBookingData) {
      toast.error("Unexpected error while generating the collect CC link");
      logError(
        "Couldn't fetch frontDeskBookingData before generating CC link for FD"
      );
      return;
    }

    if (!checkIfAllServicesAreBookableOnline()) return;

    const { slug, configuration } = frontDeskBookingData.medspaByPk;
    const { firstName, lastName, email, phone, id } = client;

    if (!email || !phone) {
      toast.error("Client's email and phone are required");
      return;
    }

    const minBookingDate = utcToMedspaZonedTime(
      addHours(new Date(), configuration.minimumBookingNoticeHours)
    );
    const maxBookingDate = utcToMedspaZonedTime(
      addHours(new Date(), configuration.maximumAdvanceBookingHours)
    );

    if (isBefore(start, minBookingDate)) {
      toast.error(
        `This medspa allows booking at least ${configuration.minimumBookingNoticeHours} hours in advance`
      );
      return;
    }

    if (isAfter(start, maxBookingDate)) {
      toast.error(
        `This medspa allows booking at most ${configuration.maximumAdvanceBookingHours} hours in advance`
      );
      return;
    }

    const cardOnFile = await getClientsCreditCard(id);

    if (cardOnFile) {
      const { brand, last4, expMonth, expYear } = cardOnFile;
      const shouldCreateVisit = await getConfirm({
        title: "Client has a card on file",
        description: `Client already has a card on file attached: ${brand}, ${last4}, ${expMonth}/${expYear}. You can book an appointment now or send a new link to collect a card anyway`,
        confirmButtonText: "Book now",
        discardButtonText: "Copy link",
      });

      if (shouldCreateVisit) {
        await handleCreateVisit();
        return;
      }
    }

    try {
      const query = {
        serviceIds: services.join(","),
        providerId,
        startStr: zonedTimeToUtcIso(start),
        endStr: zonedTimeToUtcIso(end),
        dateQuery: zonedTimeToUtcIso(start),
        firstName,
        lastName,
        email,
        phone,
        isFds: "true",
      };

      const url = `/booking/${slug}?${new URLSearchParams(query).toString()}`;

      /**
       * @see https://wolfgangrittner.dev/how-to-use-clipboard-api-in-safari/
       */
      const shortUrl = new ClipboardItem({
        "text/plain": generateShortLink(url).then(
          (text) => new Blob([text], { type: "text/plain" })
        ),
      });
      if (!shortUrl) return;
      await navigator.clipboard.write([shortUrl]);
    } catch (e) {
      toast.error(
        hasDjangoMutationError(e)
          ? e.message
          : `There was a problem with copying the URL. Please try again.`
      );
      logError(e.message);
      return;
    }

    const shouldReturnToSchedule = await getConfirm({
      title: "Success!",
      description:
        "Credit Card link saved to clipboard. Return to schedule tab?",
      confirmButtonText: "Yes",
      discardButtonText: "No",
    });

    if (shouldReturnToSchedule) {
      push(`/${medspa}/visits`);
    }
  };

  return {
    bookAsFrontDesk,
    getClientsCreditCard,
    collectCardOnBooking:
      frontDeskBookingData?.medspaByPk.configuration?.collectCardOnBooking,
    isFrontDesk,
  };
}

import { useCallback, useMemo } from "react";
import { useUserMedSpaUrlPatternPermissionsQuery } from "@/graphql/queries/userMedSpaUrlPatternPermissionsgraphql.graphql.types";
import { UrlPermission } from "@/types/urlPermission";
import { hasPermissionsToAccessUrl } from "@/utils/permissionUtils";

export type UseUrlPermissionsResult = {
  urlPermissions: string[];
  hasUrlPermission: (permission: UrlPermission) => boolean;
};

export const useUrlPermissions = (
  userMedSpaId: string | undefined
): UseUrlPermissionsResult => {
  const { data: userMedspaUrlPermissions } =
    useUserMedSpaUrlPatternPermissionsQuery({
      variables: {
        id: userMedSpaId,
      },
      skip: !userMedSpaId,
    });

  const urlPermissions = useMemo(() => {
    if (!userMedspaUrlPermissions) return [];
    return userMedspaUrlPermissions.userMedspaUrlPermissions.map(
      (p) => p.urlPattern
    );
  }, [userMedspaUrlPermissions]);

  const hasUrlPermission = useCallback(
    (url: UrlPermission) => hasPermissionsToAccessUrl(url, urlPermissions),
    [urlPermissions]
  );

  return {
    urlPermissions,
    hasUrlPermission,
  };
};

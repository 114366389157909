import { css } from "@emotion/css";
import { capitalize } from "@mui/material";
import { addMinutes, parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { EventColorPalette } from "@/config";
import { ERROR_PRIMARY, GREEN, WHITE } from "@/config/mui/colorPalette";
import { ServiceMenuItemsBasicInfoQuery } from "@/graphql/queries/serviceMenuItems.graphql.types";
import { VisitsQueryResult } from "@/graphql/queries/visit/visits.graphql.types";
import { VisitsAndAvailabilityEventsWithDateRangeQuery } from "@/graphql/queries/visit/visitsAndAvailabilityEventsWithDateRange.graphql.types";
import { ScheduleListEventType } from "@/hooks/visits/useShapedListEvents";
import { GFE_OPTIONS } from "@/store/views/serviceFlow/reviewVisits/filters/gfeStatusFilterStore";
import { VisitStatus } from "@/types";
import { ServiceMenuItemGfeStatus } from "@/types/gfe";

export const getDurationFromServices = (
  serviceIds: string[],
  availableServices: ServiceMenuItemsBasicInfoQuery["medspaServiceMenuItem"]
): number => {
  return availableServices.reduce((acc, availableService) => {
    return serviceIds.includes(availableService.id)
      ? acc + availableService.durationInMinutes
      : acc;
  }, 0);
};

export const getEndTime = (
  visit: VisitsQueryResult["data"]["visit"][0],
  availableServices: ServiceMenuItemsBasicInfoQuery
): string => {
  return addMinutes(
    parseISO(visit.startTime),
    availableServices
      ? getDurationFromServices(
          visit.serviceMenuItemLines.map((line) => line.serviceMenuItem.id),
          availableServices.medspaServiceMenuItem
        )
      : 0
  ).toISOString();
};

export const getDateString = (date: Date): string =>
  `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

export const getVisitStatusLabel = (status: VisitStatus) => {
  if (status === VisitStatus.CANCELLED_LATE) {
    return "Cancelled (late notice)";
  }

  return capitalize(status.replace("_", " "));
};

export const getSortedIntakeForms = <FS extends { id: string }>(
  forms: FS[]
): FS[] => {
  return [...forms].sort((a, b) => Number(b.id) - Number(a.id));
};

type ServiceMenuItemLines =
  VisitsAndAvailabilityEventsWithDateRangeQuery["visit"][number]["serviceMenuItemLines"];

export const getGeneralGfeVisitStatus = (
  serviceMenuItemLines: Pick<ServiceMenuItemLines[number], "gfeStatus">[]
): ServiceMenuItemGfeStatus => {
  const isAny = (status: ServiceMenuItemGfeStatus) =>
    serviceMenuItemLines.some((line) => line.gfeStatus === status);

  switch (true) {
    case isAny(ServiceMenuItemGfeStatus.CONTRAINDICATED):
      return ServiceMenuItemGfeStatus.CONTRAINDICATED;
    case isAny(ServiceMenuItemGfeStatus.NOT_INDICATED):
      return ServiceMenuItemGfeStatus.NOT_INDICATED;
    case isAny(ServiceMenuItemGfeStatus.PENDING_REVIEWER):
      return ServiceMenuItemGfeStatus.PENDING_REVIEWER;
    case isAny(ServiceMenuItemGfeStatus.FORMS_INCOMPLETE):
      return ServiceMenuItemGfeStatus.FORMS_INCOMPLETE;
    case isAny(ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS):
      return ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS;
    case isAny(ServiceMenuItemGfeStatus.INDICATED):
      return ServiceMenuItemGfeStatus.INDICATED;
    default: {
      return ServiceMenuItemGfeStatus.NOT_NEEDED;
    }
  }
};

export const getGfeStatusFilterExp = (statuses: ServiceMenuItemGfeStatus[]) => {
  const allChecked = statuses.length === GFE_OPTIONS.length;

  if (allChecked) return {};

  return {
    serviceMenuItemLines: { gfeStatus: { _in: statuses } },
  };
};

export const getServiceDevicesFilterExp = (serviceDeviceIds: string[]) => {
  if (isEmpty(serviceDeviceIds)) return {};

  return {
    appointment: {
      serviceDevicesAppointments: {
        serviceDevice: {
          id: { _in: serviceDeviceIds },
        },
      },
    },
  };
};

export const clientHasWalletItems = (
  client?: Pick<ScheduleListEventType["client"], "hasItemsInWallet">
) => {
  if (!client) return false;

  const walletSum = client.hasItemsInWallet?.aggregate?.sum?.amount ?? "0";

  return Number(walletSum) > 0;
};

export const getClientMembershipsList = (
  client?: Pick<ScheduleListEventType["client"], "clientMemberships">
) => {
  if (!client) return "";

  if (!client.clientMemberships || client.clientMemberships.length === 0)
    return "";

  return client.clientMemberships
    .map((clientMembership) => clientMembership.membership.title)
    .join(", ");
};

export const getEventColorsByState = (
  status: VisitStatus,
  eventColorPalette?: EventColorPalette
) => {
  const colorObjectByIndex = eventColorPalette ?? GREEN;

  const isCancelledOrNoShow =
    status === VisitStatus.CANCELLED ||
    status === VisitStatus.CANCELLED_LATE ||
    status === VisitStatus.NO_SHOW;

  let stripedCancelledEventClassName = undefined;
  let textStyleClassName = undefined;
  if (isCancelledOrNoShow) {
    stripedCancelledEventClassName = css`
      background: repeating-linear-gradient(
        -45deg,
        ${colorObjectByIndex[20]} 0 8px,
        ${colorObjectByIndex[40]} 8px 16px
      );
    `;

    textStyleClassName = css`
      color: ${colorObjectByIndex[80]};
      text-decoration: line-through !important;
    `;
  }

  const textColorsByStatus = {
    [VisitStatus.SCHEDULED]: colorObjectByIndex[100],
    [VisitStatus.CONFIRMED]: colorObjectByIndex[100],
    [VisitStatus.COMPLETED]: colorObjectByIndex[20],
    [VisitStatus.CANCELLED]: textStyleClassName,
    [VisitStatus.CANCELLED_LATE]: textStyleClassName,
    [VisitStatus.NO_SHOW]: textStyleClassName,
  }[status];

  const backgroundColorsByStatus = {
    [VisitStatus.SCHEDULED]: WHITE,
    [VisitStatus.CONFIRMED]: colorObjectByIndex[40],
    [VisitStatus.COMPLETED]: colorObjectByIndex[80],
    [VisitStatus.CANCELLED]: stripedCancelledEventClassName,
    [VisitStatus.CANCELLED_LATE]: stripedCancelledEventClassName,
    [VisitStatus.NO_SHOW]: stripedCancelledEventClassName,
  }[status];

  const borderColorByStatus = {
    [VisitStatus.SCHEDULED]: colorObjectByIndex[80],
    [VisitStatus.CONFIRMED]: colorObjectByIndex[80],
    [VisitStatus.COMPLETED]: colorObjectByIndex[80],
    [VisitStatus.CANCELLED]: colorObjectByIndex[80],
    [VisitStatus.CANCELLED_LATE]: ERROR_PRIMARY,
    [VisitStatus.NO_SHOW]: ERROR_PRIMARY,
  }[status];

  return {
    borderColor: borderColorByStatus,
    textColor: !isCancelledOrNoShow ? textColorsByStatus : undefined,
    backgroundColor: !isCancelledOrNoShow
      ? backgroundColorsByStatus
      : undefined,
    classNames: isCancelledOrNoShow
      ? [backgroundColorsByStatus, textColorsByStatus]
      : undefined,
  };
};
